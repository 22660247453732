import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'level-svg',
    templateUrl: './levelsvg.component.html',
    styleUrls: ['./levelsvg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LevelsvgComponent implements OnInit {

    @Input("value") value: number;
    @Input("animate") animate: boolean = false;


    constructor() {
    }

    ngOnInit() {
    }

    public getLevelClipPath() {
        return {clipPath: `inset(${this.value * 100}% 0px 0px 0px)`};
    }


}
