<ion-content [scrollY]="false">
  <div class="root">
    <!--<waveform></waveform>//-->
    <dual-waveform-v2 [data1]="waveformCache1$|async" [data2]="waveformCache2$|async"
      [debugMode]="false"></dual-waveform-v2>
    <div class="dualsoundplayer">
      <div class="player">
        <sound-player #soundPlayer1 [playerID]="1"></sound-player>
        @if((view$ | async) == Views.TWO_PLAYLISTS) {
        <play-list-panel [playerID]="1"></play-list-panel>
        }
      </div>
      <widgets></widgets>
      <div class="player">
        <sound-player #soundPlayer2 [playerID]="2"></sound-player>
        @if((view$ | async) == Views.TWO_PLAYLISTS) {
        <play-list-panel [playerID]="2"></play-list-panel>
        }
      </div>
    </div>
    <mixer *ngIf="mixerVisible$ | async"></mixer>
    <beat-maker *ngIf="beatMakerVisible$ | async"></beat-maker>
    <music-archive *ngIf="!mobile && (musicArchiveVisible$ | async)"></music-archive>
    <music-archive-mobile *ngIf="(musicArchiveVisible$ | async) && mobile"></music-archive-mobile>
  </div>
</ion-content>
<ion-footer>
  <footer></footer>
</ion-footer>