import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { IonButton } from '@ionic/angular/standalone';
import { UMButtonComponent } from '../../../components/umbutton/umbutton.component';
import { TimePipe } from '../../../shared-ui/time.pipe';

@Component({
  standalone: true,
  imports: [
    IonButton,
    UMButtonComponent,
    AsyncPipe,
    TimePipe
  ],
  selector: 'play-list-actions',
  templateUrl: './play-list-actions.component.html',
  styleUrls: ['./play-list-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayListActionsComponent implements OnInit {
  @Input('noTracks') noTracks = 0;
  @Input('noTracksLength') noTracksLength = 0;

  @Output('add')
  onAdd = new EventEmitter();

  @Output('remove')
  onRemove = new EventEmitter();

  @Output('config')
  onConfig = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  handleConfig() {
    throw new Error('Method not implemented.');
  }
  handleRemove() {
    this.onRemove.emit();
  }
  handleAdd() {
    throw new Error('Method not implemented.');
  }
}
