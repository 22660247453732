import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonButton } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { StarRatingModule } from 'angular-star-rating';
import { DndModule } from 'ngx-drag-drop';
import { MenuItem } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { RatingModule } from 'primeng/rating';
import { Table, TableModule } from 'primeng/table';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import {
  cmAddDirectoryToPlaylistAction,
  cmAddItunesPlaylistsToPlaylistAction,
  cmAddItunesTrackToPlaylistAction,
  cmAddPlaylistsToPlaylistAction,
  cmAddTrackToPlaylistAction,
  cmAnalyseTrackAction,
  cmBpmSetAction,
  cmChangeId3TagsAction,
  cmExportTrackAction,
  cmNormalisizeVolumeAction,
  cmOpenFileManagerAction,
  cmReadId3TagsAction,
  cmRemoveAllTracksFromPlaylistAction,
  cmRemoveFromHarddriveAction,
  cmRemoveMarkedTracksFromPlaylistAction,
  cmReshufflyPlaylistAction,
  cmSavePlaylistAction,
  cmSearchCoverAction,
} from '../../../core/redux/actions/archive.actions';
import { loadIntoPlayerAction } from '../../../core/redux/actions/player.actions';
import { isLoadingTracks, tracks } from '../../../core/redux/reducers/archive.reducer';
import { Track } from '../../../music-archive/track';
import { TimePipe } from '../../../shared-ui/time.pipe';
@Component({
  selector: 'play-list-table',
  standalone: true,
  imports: [CommonModule, FormsModule, TableModule, IonButton, StarRatingModule, RatingModule, TimePipe, ContextMenuModule, DndModule],
  templateUrl: './play-list-table.component.html',
  styleUrl: './play-list-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayListTableComponent implements OnInit {
  _selectedTracks: Track[];

  @ViewChild('table') table: Table;

  @Output('selectedTracks') selectedTracks = new EventEmitter<Track[]>();
  @Output('trackPositionChange') trackPositionChange = new EventEmitter<{ dragIndex: number; dropIndex: number }>();
  @Output('changedRating') changedRating = new EventEmitter<number>();

  @Input() playerID: number;

  // UI bindings
  menuItemsPlaylist: MenuItem[];
  enabledFirst: boolean = false;

  draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost
    data: 'myDragData',
    effectAllowed: 'copy',
    disable: false,
    handle: false,
  };

  editable$ = new BehaviorSubject(false);
  tracks$: Observable<Track[]> = this.store.select(tracks);
  tracksLoading$ = this.store.select(isLoadingTracks); // TODO: hier müssen wir das laden der playlisten tracken

  // injects
  // playlistEffects = inject(PlaylistEffects);

  cols = [
    { field: 'no', header: 'No', width: '46px' },
    { field: 'artist', header: 'Artist', width: '' },
    { field: 'title', header: 'Title', width: '' },
    { field: 'bpm', header: 'BPM', width: '100px' },
    { field: 'length', header: 'Length', width: '100px' },
    { field: 'rating', header: 'Rating', width: '100px' },
    { field: 'genre', header: 'Genre', width: '100px' },
  ];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.setupMenu(true);
  }

  getSelectedTrack(): Track {
    return this._selectedTracks[0];
  }

  private setupMenu(disable: boolean): void {
    this.menuItemsPlaylist = [
      {
        label: 'In Player laden',
        disabled: disable,
        command: () => this.store.dispatch(loadIntoPlayerAction({ playerID: this.playerID, track: this.getSelectedTrack() })),
      },
      { separator: true },
      {
        label: 'Musik hinzufügen',
        items: [
          { label: 'Titel hinzufügen', command: () => this.store.dispatch(cmAddTrackToPlaylistAction()) },
          { label: 'Verzeichnis hinzufügen', command: () => this.store.dispatch(cmAddDirectoryToPlaylistAction()) },
          { label: 'iTunes-Titel hinzufügen', command: () => this.store.dispatch(cmAddItunesTrackToPlaylistAction()) },
          { separator: true },
          { label: 'Playlisten hinzufügen', command: () => this.store.dispatch(cmAddPlaylistsToPlaylistAction()) },
          { label: 'iTunes-Playlisten hinzufügen', command: () => this.store.dispatch(cmAddItunesPlaylistsToPlaylistAction()) },
        ],
      },
      { separator: true },
      {
        label: 'Markerte Titel entfernen',
        disabled: disable,
        command: () => this.store.dispatch(cmRemoveMarkedTracksFromPlaylistAction({ list: 0 })),
      },
      {
        label: 'Alle Titel entfernen',
        command: () => this.store.dispatch(cmRemoveAllTracksFromPlaylistAction()),
      },
      {
        label: 'Alles selektieren',
        command: () => this.handleSelectAllTracks(), // this.store.dispatch(cmSelectAllTracksInPlaylistAction()),
      },
      { separator: true },
      {
        label: 'Von Festplatte löschen',
        disabled: disable,
        command: () => this.store.dispatch(cmRemoveFromHarddriveAction({ list: 0 })),
      },
      { separator: true },
      {
        label: 'Playlist speichern',
        command: () => this.store.dispatch(cmSavePlaylistAction()),
      },
      {
        label: 'Playlist neu mischen (shuffle)',
        command: () => this.store.dispatch(cmReshufflyPlaylistAction()),
      },
      { separator: true },
      {
        label: 'Bearbeiten',
        items: [
          { label: 'BPM setzen', command: () => this.store.dispatch(cmBpmSetAction({ position: 0 })) },
          { label: 'ID3-Tags lesen', command: () => this.store.dispatch(cmReadId3TagsAction({ position: 0 })), disabled: disable },
          { label: 'ID3-Tags ändern', command: () => this.store.dispatch(cmChangeId3TagsAction({ position: 0 })), disabled: disable },
          { separator: true },
          { label: 'Cover suchen', command: () => this.store.dispatch(cmSearchCoverAction({ position: 0 })), disabled: disable },
        ],
      },
      {
        label: 'Analysieren',
        items: [
          {
            label: 'Lautstärke normalisieren',
            command: () => this.store.dispatch(cmNormalisizeVolumeAction({ position: 0 })),
            disabled: disable,
          },
          { label: 'Track analysieren', command: () => this.store.dispatch(cmAnalyseTrackAction({ position: 0 })), disabled: disable },
        ],
      },
      {
        label: 'Extras',
        items: [
          { label: 'Im Finder anzeigen', command: () => this.store.dispatch(cmOpenFileManagerAction()) },
          { label: 'Track exportieren', command: () => this.store.dispatch(cmExportTrackAction({ position: 0 })) },
        ],
      },
    ];
  }

  loadTrackIntoPlayer(track: Track, playerID: number): void {
    this.store.dispatch(loadIntoPlayerAction({ playerID, track }));
  }

  onRowSelect(event: any): void {
    // console.log('### [PLAYLIST] onRowSelect: event', event, this._selectedTracks);
    this.selectedTracks.emit(event.data);
    if (!this.enabledFirst) {
      this.enabledFirst = true;
      this.setupMenu(false);
    }
  }

  onRowUnselect(selectedTracks: any): void {
    console.log('### [PLAYLIST] unselected tracks', this._selectedTracks);
    this.selectedTracks.emit(selectedTracks);
  }

  handleLoadTrack(track: Track): void {
    this.store.dispatch(loadIntoPlayerAction({ playerID: this.playerID, track: track }));
  }

  onRowReorder(event: { dragIndex: number; dropIndex: number }): void {
    console.log('### [PLAYLIST] reorder row', event);
    this.trackPositionChange.emit(event);
  }

  onStarRatingChange(event: any): void {
    console.log('### [PLAYLIST] star rating change', event);
    this.changedRating.emit(event);
  }

  handleSelectAllTracks() {
    console.log('>>> this.table.', this.table);

    this.tracks$
      .pipe(
        take(1),
        tap((tracks) => {
          this._selectedTracks = [...tracks];
        })
      )
      .subscribe();
  }

  handleDblClick(): void {
    this.editable$.next(true);
  }

  handleDisableEdit(): void {
    this.editable$.next(false);
  }

  handleDragStart(evt, track: Track): void {
    console.log('>>> handleDragStart: track', track);
    evt.dataTransfer.setData('text', evt.target.id);
  }
  handleDragEnd(evt): void {
    console.log('>>> handleDragEnd: track', evt);
  }

  onDragStart(event: DragEvent, track) {
    console.log('drag started', JSON.stringify(event, null, 2));
    this.draggable.data = track;
  }
}
