import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { AngularSplitModule } from 'angular-split';
import { ComponentsModule } from '../components/components.module';
import { MusicArchiveMobileModule } from '../music-archive/music-archive-mobile/music-archive-mobile.module';
import { MusicArchiveModule } from '../music-archive/music-archive.module';
import { ActionTriggerModule } from './action-trigger/action-trigger.module';
import { BeatmakerModule } from './beatmaker/beatmaker.module';
import { FooterModule } from './footer/footer.module';
import { MainPage } from './main.page';
import { MixerComponent } from './mixer/mixer.component';
import { MixerModule } from './mixer/mixer.module';
import { PlayListPanelComponent } from './playlists/play-list-panel/play-list-panel.component';
import { SoundPlayerModule } from './sound-player/sound-player.module';
import { TurntableComponent } from './sound-player/turntable/turntable.component';
import { WaveformNoRouteModule } from './waveform/waveform-noroute.module';
import { AudioSamplerComponent } from './widgets/audio-sampler/audio-sampler.component';
import { WidgetsModule } from './widgets/widgets.module';

const routes: Routes = [
  {
    path: '',
    component: MainPage,
  },
  {
    path: 'mixer',
    component: MixerComponent,
    // loadChildren: './mixer/mixer.module#MixerModule'
  },
  {
    path: 'turntable',
    component: TurntableComponent,
    // loadChildren: './mixer/mixer.module#MixerModule'
  },
  {
    path: 'audiosampler',
    component: AudioSamplerComponent,
    // loadChildren: './mixer/mixer.module#MixerModule'
  },
  {
    path: 'actiontrigger',
    loadChildren: () => import('./action-trigger/action-trigger.module').then((m) => m.ActionTriggerModule),
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MixerModule,
    SoundPlayerModule,
    ComponentsModule,
    WidgetsModule,
    MusicArchiveModule,
    BeatmakerModule,
    FooterModule,
    WaveformNoRouteModule,
    MusicArchiveMobileModule,
    ActionTriggerModule,
    AngularSplitModule,
    PlayListPanelComponent,
    RouterModule.forChild(routes),
  ],
  declarations: [MainPage],
  providers: [],
  exports: [],
})
export class MainPageModule {}
