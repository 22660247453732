<div class="root">
  <section class="left">
    <um-button (click)="handleAdd()">Add</um-button>
    <um-button (click)="handleRemove()">Remove</um-button>
    <um-button (click)="handleConfig()">Config</um-button>
  </section>
  
  <div class="middle">
    {{ noTracks }} Tracks, {{ noTracksLength | time: 'HH:mm:ss' }}
  </div>
  
  <section class="right">
    <um-button>Prev</um-button>
    <um-button>Next</um-button>
    <um-button>Single</um-button>
    <um-button>Party</um-button>
  </section>
</div>