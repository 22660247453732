<div class="root">
  <div class="logo">
    <img *ngIf="false" src="./assets/img/um-logo.png" height="30" />
    <img src="./assets/img/ultramixer6x-logo4a.svg" height="30" />
  </div>

  <div class="segment">
    <div class="label">Ansicht</div>
    <ion-segment #buttons [value]="Views.TWO_PLAYLISTS" class="buttons" color="dark"
      (ionChange)="handleViewChanged($event)">
      <ion-segment-button [value]="Views.TWO_PLAYLISTS">
        <ion-label>2-Playlists</ion-label>
      </ion-segment-button>
      <ion-segment-button [value]="Views.WAITLIST">
        <ion-label>Waitlist</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>

  <div class="segment">
    <div class="label">Player</div>
    <ion-segment #buttons [value]="NoPlayers.TWO_PLAYERS" class="buttons" color="dark"
      (ionChange)="handlePlayerChanged($event)">
      <ion-segment-button [value]="NoPlayers.TWO_PLAYERS">
        <ion-label>2</ion-label>
      </ion-segment-button>
      <ion-segment-button [value]="NoPlayers.FOUR_PLAYERS">
        <ion-label>4</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>

  <div class="segment">
    <div class="label">Modus</div>
    <ion-segment #buttons value="music" class="buttons" color="dark" (ionChange)="segmentChanged($event)">
      <ion-segment-button value="mixer">
        <ion-label>Mixer</ion-label>
      </ion-segment-button>
      <ion-segment-button value="music">
        <ion-label>Music</ion-label>
      </ion-segment-button>
      <ion-segment-button value="beatmaker">
        <ion-label>BeatMaker</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>

  <!--
  <button (click)="handleShowMixer()" class="mini-button">Mixer</button>
  <button (click)="handleShowMusicArchive()" class="mini-button">Music</button>
  <button (click)="handleShowBeatMaker()" class="mini-button">BeatMaker</button>
  <button (click)="toggleFullscreenMode()" class="mini-button">Fullscreen</button>
  -->
</div>