import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from '../../components/components.module';

import { DndModule } from 'ngx-drag-drop';
import { NgxSliderModule } from 'ngx-slider-v2';
import { UMButtonComponent } from '../../components/umbutton/umbutton.component';
import { SharedUiModule } from '../../shared-ui/shared-ui.module';
import { TimePipe } from '../../shared-ui/time.pipe';
import { WaveformModule } from '../waveform/waveform.module';
import { DisplayComponent } from './display/display.component';
import { PitchFaderComponent } from './pitch-fader/pitch-fader.component';
import { SearchComponent } from './search/search.component';
import { SoundPlayerComponent } from './sound-player.component';
import { TrackTickerComponent } from './track-ticker/track-ticker.component';
import { TurntableComponent } from './turntable/turntable.component';
import { Turntable2Component } from './turntable2/turntable2.component';
@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    IonicModule,
    SharedUiModule,
    WaveformModule,
    NgxSliderModule,
    TimePipe,
    UMButtonComponent,
    DndModule,
    //StoreModule.forFeature('player1', reducer),
    //StoreModule.forFeature('player2', reducer),
  ],
  declarations: [
    SoundPlayerComponent,
    TurntableComponent,
    Turntable2Component,
    SearchComponent,
    PitchFaderComponent,
    DisplayComponent,
    TrackTickerComponent,
  ],
  exports: [SoundPlayerComponent, Turntable2Component],
})
export class SoundPlayerModule {}
