import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { MenuItem, TreeNode } from 'primeng/api';
import { Tree } from 'primeng/tree';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AddDirectoryAction,
  AddGroupAction,
  RemoveGroupAction,
  RenameGroupAction,
  loadGroupsAction,
  loadTracksAction,
} from '../../core/redux/actions/archive.actions';
import { groups, isLoadingGroups, loadingGroupID, selectedGroupID } from '../../core/redux/reducers/archive.reducer';

@Component({
  selector: 'app-groups-browser',
  templateUrl: './groups-browser.component.html',
  styleUrls: ['./groups-browser.component.scss'],
})
export class GroupsBrowserComponent implements OnInit, AfterViewInit {
  groups$: Observable<TreeNode[]> = this.store.select(groups).pipe(map((groups: TreeNode[]) => structuredClone(groups)));
  groups: TreeNode[];

  isLoadingGroups$: Observable<boolean> = this.store.select(isLoadingGroups);
  loadingGroupID$: Observable<string> = this.store.select(loadingGroupID);
  selectedGroupID$: Observable<string> = this.store.select(selectedGroupID);

  @ViewChild('tree', { static: false }) tree: Tree;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  selectedFiles: TreeNode[];
  selectedGroup: TreeNode;

  @Output() groupSelected = new EventEmitter<TreeNode>();

  menuItems: MenuItem[];

  constructor(private store: Store, public alertController: AlertController) {
    // TODO: unsubscribe
    this.groups$.subscribe((groups) => {
      this.groups = groups;
      // load first group on startup
      // todo: maybe it's better to make a new action for it (unit tested)
      if (!this.selectedGroup && groups && groups.length > 0) {
       // console.log('this.groups: ', groups);
        this.store.dispatch(loadTracksAction({ groupID: groups[0].key }));
      }
    });

    // TODO: unsubscribe
    this.selectedGroupID$
      .pipe(
        map((groupID: string) => {
          const treeNode = this.groups.find((value: TreeNode, index: number) => {
            if (value.key === groupID) {
              return true;
            }
          });
          return treeNode;
        })
      )
      .subscribe((selectedGroup: TreeNode) => {
        this.selectedGroup = selectedGroup;
      });
  }

  ngOnInit() {
    this.menuItems = [
      /*
            {
                label: 'Add to Player',
                icon: 'pi pi-fw pi-plus'
            },
            {
                label: 'Add to Waitlist',
                icon: 'pi pi-fw pi-pencil',
            },
            */
      { separator: true },
      {
        label: 'Remove Group',
        icon: 'pi pi-fw pi-remove',
        command: this.handleRemoveGroup.bind(this),
      },
      { separator: true },
      {
        label: 'Rename Group',
        icon: 'pi pi-fw pi-pencil',
        command: this.handleRenameGroup.bind(this),
      },
    ];
  }

  ngAfterViewInit(): void {
    this.store.dispatch(loadGroupsAction());
  }

  loadNode(event: any) {
    console.log('event');
    console.log(event);

    if (event.node) {
      // in a real application, make a call to a remote url to load children of the current node and add the new nodes as children
      /*
            this.dataService.getFiles().subscribe((files) => {
                event.node.children = files;
                this.loading = false;
            });
            */
    }
  }

  handleAddDirectory() {
    console.log('add');
    this.fileInput.nativeElement.click();

    /*
        window["requestFileSystem"] = window["requestFileSystem"] || window["webkitRequestFileSystem"];

        window["requestFileSystem"](window["TEMPORARY"], 1024 * 1024,
            function (fs) {
                fs.root.getDirectory('MyPictures', {create: true},
                    function (dirEntry) {
                        console.log("dirEntry");
                        console.log(dirEntry);
                    },
                    function (error) {
                        console.log("err");
                        console.log(error);
                    });
            },
            function (error) {
                console.log("err");
                console.log(error);
            });
            */
  }

  handleGroupSelect(event) {
    this.groupSelected.emit(event.node);
  }

  async onFileChange(event) {
    /*
        let files: FileList = event.target.files;
        for (let i = 0; i < files.length; i++) {
            // @ts-ignore
            let path = files[i].webkitRelativePath;
            console.log("path");
            console.log(path);
        }
        */

    const dir: File = event.target.files[0];
    console.log('dir: ', dir);
    this.store.dispatch(new AddDirectoryAction({ dir }));
  }

  private handleRemoveGroup() {
    this.store.dispatch(new RemoveGroupAction({ group: this.selectedGroup }));
  }

  private handleRenameGroup() {
    this.presentRenameGroupAlert(this.selectedGroup);
  }

  async presentRenameGroupAlert(group: TreeNode) {
    const alert = await this.alertController.create({
      header: 'Rename Group',
      message: '',
      mode: 'ios',
      inputs: [
        {
          name: 'groupName',
          value: group.label,
          type: 'text',
          placeholder: 'Name of Group',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          },
        },
        {
          text: 'Ok',
          handler: (data) => {
            this.store.dispatch(new RenameGroupAction({ group, newName: data.groupName }));
          },
        },
      ],
    });

    await alert.present();
  }

  async handleNewGroup() {
    const alert = await this.alertController.create({
      header: 'Rename Group',
      message: '',
      mode: 'ios',
      inputs: [
        {
          name: 'groupName',
          value: '',
          type: 'text',
          placeholder: 'Name of Group',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          },
        },
        {
          text: 'Ok',
          handler: (data) => {
            this.store.dispatch(new AddGroupAction({ groupName: data.groupName }));
          },
        },
      ],
    });

    await alert.present();
  }
}
