import { NoPlayers } from './../core/enums/no-players.enum';
import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Modes } from '../core/enums/modes.enum';
import { Views } from '../core/enums/views.enum';
import {
  getMode,
  getNoPlayers,
  getView,
  isBeatMakerVisible,
  isMixerVisible,
  isMusicArchiveVisible,
} from '../core/redux/reducers/general.reducer';
import { getWaveformCache } from '../core/redux/reducers/player.reducer';
import { SoundPlayerComponent } from './sound-player/sound-player.component';
import { WaveformCache } from './waveform/WaveformCache';
import { VideoMixComponent } from './widgets/video-mix/video-mix.component';

@Component({
  selector: 'main',
  templateUrl: './main.page.html',
  styleUrls: ['./main.page.scss'],
})
export class MainPage implements OnInit, AfterViewInit {
  @ViewChild('soundPlayer1', { static: false }) soundPlayer1: SoundPlayerComponent;
  @ViewChild('soundPlayer2', { static: false }) soundPlayer2: SoundPlayerComponent;
  @ViewChild('videoMix', { static: false }) videoMix: VideoMixComponent;

  Views = Views;
  Modes = Modes;
  NoPlayers = NoPlayers;

  // TOOD: check error
  waveformCache1$: Observable<WaveformCache> = this.store.select(getWaveformCache(1));
  waveformCache2$: Observable<WaveformCache> = this.store.select(getWaveformCache(2));
  mixerVisible$: Observable<boolean> = this.store.select(isMixerVisible);
  beatMakerVisible$: Observable<boolean> = this.store.select(isBeatMakerVisible);
  musicArchiveVisible$: Observable<boolean> = this.store.select(isMusicArchiveVisible);

  mobile = false;

  // UI bindings
  view$ = this.store.select(getView);
  mode$ = this.store.select(getMode);
  noPlayers$ = this.store.select(getNoPlayers);

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.checkMobile(window.innerWidth);
  }

  ngAfterViewInit(): void {
    //  this.store.dispatch(loadGroupAction({ groupID: null }));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkMobile(event.target.innerWidth);
  }

  private checkMobile(width) {
    this.mobile = width < 600;
  }
}
