<svg>
    <defs>
        <linearGradient id="levelGradient" x1="0" x2="0" y1="0" y2="100%">
            <stop offset="0%" stop-color="red" />
            <stop offset="20%" stop-color="yellow" />
            <stop offset="100%" stop-color="green" />
        </linearGradient>

        <clipPath id="levelClipPath">

            <rect x="0" y="100%" width="100%" height="100%">
                <animate attributeType="XML"
                         attributeName="y"
                         from="30%" to="10%"
                         dur="500ms" repeatCount="indefinite"/>
            </rect>
        </clipPath>
    </defs>

    <g>
        <rect x="0" y="0" width="30" height="200" fill="url(#levelGradient)" opacity="0.3"/>

        <rect x="0" y="0" width="30" height="200" fill="url(#levelGradient)" clip-path="url(#levelClipPath)"/>
    </g>
</svg> 