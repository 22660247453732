import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { Track } from '../../../music-archive/track';
import { Group } from '../../../services/group';
import { ActionWithPayload } from './action.interface';

const ACTION_TYPE = '[Arcihve]';

export const loadTracksAction = createAction(`${ACTION_TYPE} Load tracks`, props<{ groupID: string }>());
export const loadTracksSucceededAction = createAction(`${ACTION_TYPE} Load tracks succeeded`, props<{ tracks: Track[] }>());

export const loadGroupsAction = createAction(`${ACTION_TYPE} Load groups`);
export const loadGroupsSucceededAction = createAction(`${ACTION_TYPE} Load groups succeeded`, props<{ groups: TreeNode[] }>());
export const loadGroupsFailedAction = createAction(`${ACTION_TYPE} Load groups failed`, props<{ error: HttpErrorResponse }>());

export const cmAddTrackToPlaylistAction = createAction(`${ACTION_TYPE} Add track to playlist`);
export const cmAddDirectoryToPlaylistAction = createAction(`${ACTION_TYPE} Add directory to playlist`);
export const cmAddItunesTrackToPlaylistAction = createAction(`${ACTION_TYPE} Add iTunes track to playlist`);
export const cmAddPlaylistsToPlaylistAction = createAction(`${ACTION_TYPE} Add playlists to playlist`);
export const cmAddItunesPlaylistsToPlaylistAction = createAction(`${ACTION_TYPE} Add iTunes playlists to playlist`);
export const cmRemoveMarkedTracksFromPlaylistAction = createAction(
  `${ACTION_TYPE} Remove marked tracks from playlist`,
  props<{ list: number }>()
);
export const cmRemoveAllTracksFromPlaylistAction = createAction(`${ACTION_TYPE} Remove all tracks from playlist`);
export const cmSelectAllTracksInPlaylistAction = createAction(`${ACTION_TYPE} Select all tracks in playlist`);
export const cmRemoveFromHarddriveAction = createAction(`${ACTION_TYPE} Remove from harddrive`, props<{ list: number }>());
export const cmSavePlaylistAction = createAction(`${ACTION_TYPE} Save playlist`);
export const cmReshufflyPlaylistAction = createAction(`${ACTION_TYPE} Reshuffle playlist`);
export const cmBpmSetAction = createAction(`${ACTION_TYPE} Bpm set`, props<{ position: number }>());
export const cmReadId3TagsAction = createAction(`${ACTION_TYPE} Read id3 tags`, props<{ position: number }>());
export const cmChangeId3TagsAction = createAction(`${ACTION_TYPE} Change id3 tags`, props<{ position: number }>());
export const cmSearchCoverAction = createAction(`${ACTION_TYPE} Search cover`, props<{ position: number }>());
export const cmNormalisizeVolumeAction = createAction(`${ACTION_TYPE} Normalisize volume`, props<{ position: number }>());
export const cmAnalyseTrackAction = createAction(`${ACTION_TYPE} Analyse track`, props<{ position: number }>());
export const cmOpenFileManagerAction = createAction(`${ACTION_TYPE} Open file-manager`);
export const cmExportTrackAction = createAction(`${ACTION_TYPE} Export track`, props<{ position: number }>());

export class ArchiveActions {
  static LOAD_TRACKS_FAILED = '[Archive] Load tracks failed';

  static ADD_DIRECTORY = '[Archive] Add directory';
  static ADD_DIRECTORY_SUCCESSFUL = '[Archive] Add directory successful';
  static ADD_DIRECTORY_FAILED = '[Archive] Add directory failed';

  static REMOVE_GROUP = '[Archive] Remove group';
  static REMOVE_GROUP_SUCCESSFUL = '[Archive] Remove group successful';
  static REMOVE_GROUP_FAILED = '[Archive] Remove group failed';

  static GROUP_CHANGED = '[Archive] Group has changed';

  static RENAME_GROUP = '[Archive] Rename group';
  static RENAME_GROUP_SUCCESSFUL = '[Archive] Rename group successful';
  static RENAME_GROUP_FAILED = '[Archive] Rename group failed';

  static ADD_GROUP = '[Archive] Add group';
  static ADD_GROUP_SUCCESSFUL = '[Archive] Add group successful';
  static ADD_GROUP_FAILED = '[Archive] Add group failed';
}

export class LoadTracksFailedAction implements ActionWithPayload {
  readonly type = ArchiveActions.LOAD_TRACKS_FAILED;

  constructor(public payload: { groupID: string; error: HttpErrorResponse }) {}
}

export class AddDirectoryAction implements ActionWithPayload {
  readonly type = ArchiveActions.ADD_DIRECTORY;

  constructor(public payload: { dir: File }) {}
}

export class AddDirectorySuccessfulAction implements ActionWithPayload {
  readonly type = ArchiveActions.ADD_DIRECTORY_SUCCESSFUL;

  constructor() {}
}

export class AddDirectoryFailedAction implements ActionWithPayload {
  readonly type = ArchiveActions.ADD_DIRECTORY_FAILED;

  constructor() {}
}

export class RemoveGroupAction implements ActionWithPayload {
  readonly type = ArchiveActions.REMOVE_GROUP;

  constructor(public payload: { group: TreeNode }) {}
}

export class RemoveGroupSuccessfulAction implements ActionWithPayload {
  readonly type = ArchiveActions.REMOVE_GROUP_SUCCESSFUL;

  constructor(public payload: { group: TreeNode }) {}
}

export class GroupChangedAction implements ActionWithPayload {
  readonly type = ArchiveActions.GROUP_CHANGED;

  constructor(public payload: { group: Group }) {}
}

export class RenameGroupAction implements ActionWithPayload {
  readonly type = ArchiveActions.RENAME_GROUP;

  constructor(public payload: { group: TreeNode; newName: string }) {}
}

export class RenameGroupSuccessfulAction implements ActionWithPayload {
  readonly type = ArchiveActions.RENAME_GROUP_SUCCESSFUL;

  constructor(public payload: { group: TreeNode }) {}
}

export class AddGroupAction implements ActionWithPayload {
  readonly type = ArchiveActions.ADD_GROUP;

  constructor(public payload: { groupName: string }) {}
}

export class AddGroupSuccessfulAction implements ActionWithPayload {
  readonly type = ArchiveActions.ADD_GROUP_SUCCESSFUL;

  constructor(public payload: { group: TreeNode }) {}
}
