import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '../../components/components.module';
import { UMButtonComponent } from '../../components/umbutton/umbutton.component';
import { BeatMakerBarComponent } from './beat-maker/beat-maker-bar/beat-maker-bar.component';
import { BeatMakerComponent } from './beat-maker/beat-maker.component';

@NgModule({
  declarations: [BeatMakerComponent, BeatMakerBarComponent],
  imports: [CommonModule, ComponentsModule, UMButtonComponent],
  exports: [BeatMakerComponent],
})
export class BeatmakerModule {}
