import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getProgress } from '../../../core/redux/reducers/player.reducer';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input('playerID') playerID: number;

  progress$: Observable<number>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.progress$ = this.store.select(getProgress(this.playerID)).pipe(map((value: number) => value * 100));
  }
}
