import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'level-canvas',
  templateUrl: './levelcanvas.component.html',
  styleUrls: ['./levelcanvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelCanvasComponent implements OnInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  @Input('value') value: number;
  @Input('animate') animate: boolean = false;
  private ctx: CanvasRenderingContext2D;

  private size = 128;

  constructor() {}

  ngOnInit() {
    console.log('canvas');
    console.log(this.canvas);

    const canvas: HTMLCanvasElement = this.canvas.nativeElement;
    this.ctx = canvas.getContext('2d');

    this.animateNow();

    /*
        let gradient = ctx.createLinearGradient(0,0,0,200);
        // Add three color stops
        gradient.addColorStop(1, 'green');
        gradient.addColorStop(0.2, 'yellow');
        gradient.addColorStop(0, 'red');


        ctx.fillStyle = gradient;
        ctx.fillRect(0,0,30,200);
        */
  }

  animateNow() {
    window.requestAnimationFrame(() => {
      window.setTimeout(this.animateNow.bind(this), 1000 / 60, new Date().getTime());
    });
    this.draw();
  }

  draw() {
    this.ctx.clearRect(0, 0, this.size, this.size);
    this.ctx.translate(this.size / 2, this.size / 2);
    this.ctx.rotate((2 * Math.PI) / 180);
    this.ctx.translate(-this.size / 2, -this.size / 2);

    this.drawCircle();
    this.drawLine();
  }

  public getLevelClipPath() {
    return { clipPath: `inset(${this.value * 100}% 0px 0px 0px)` };
  }

  private drawCircle() {
    this.ctx.beginPath();
    this.ctx.arc(this.size / 2, this.size / 2, this.size / 2, 0, Math.PI * 4, true);
    this.ctx.strokeStyle = '#666';
    this.ctx.fillStyle = '#000';
    this.ctx.lineWidth = 12;
    this.ctx.stroke();
    this.ctx.fill();
  }

  private drawLine() {
    this.ctx.strokeStyle = 'yellow';
    this.ctx.moveTo(this.size / 2, this.size / 2);
    this.ctx.lineTo(this.size / 2, 0);
    this.ctx.stroke();
  }
}
