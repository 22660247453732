import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { noTracks, noTracksLength } from '../../../core/redux/reducers/archive.reducer';
import { PlayListActionsComponent } from '../play-list-actions/play-list-actions.component';
import { PlayListTableComponent } from '../play-list-table/play-list-table.component';
import { removeSelectedTracksAction } from '../redux/playlist.actions';
import { Track } from '../../../music-archive/track';

@Component({
  standalone: true,
  imports: [PlayListTableComponent, PlayListActionsComponent, AsyncPipe],
  selector: 'play-list-panel',
  templateUrl: './play-list-panel.component.html',
  styleUrls: ['./play-list-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayListPanelComponent implements OnInit {
  // services
  private store = inject(Store);

  @Input() playerID: number;

  // UI bindings
  noTracks$: Observable<number> = this.store.select(noTracks);
  noTracksLength$: Observable<number> = this.store.select(noTracksLength);

  constructor() {}

  ngOnInit(): void {}

  handleConfig(): void {
    throw new Error('Method not implemented.');
  }
  handleRemove(): void {
    this.store.dispatch(removeSelectedTracksAction({ playerID: 1, tracks: [] }));
  }
  handleAdd(): void {
    throw new Error('Method not implemented.');
  }

  onSelectedTracks(tracks: Track[]): void {
    console.log('### [PLAY-List] tracks', tracks);
  }

  onRatingChange(newRating: number): void {
    console.log('### [PLAY-List] newRating', newRating);
  }

  onTrackPositionChange(event: { dragIndex: number; dropIndex: number }): void {
    console.log('### [PLAY-List] main row-change', event);
  }
}
