<div class="eq player-1">
  <div class="eq-knobs">
    <knob label="HIGH" (kill)="handleKillEqHigh(1)" (value)="handleEQHigh(1, $event)"></knob>
    <knob label="MID" (kill)="handleKillEqMid(1)" (value)="handleEQMid(1, $event)"></knob>
    <knob label="LOW" (kill)="handleKillEqLow(1)" (value)="handleEQLow(1, $event)"></knob>
  </div>
  <div class="eq-knobs">
    <knob label="Flanger" (kill)="handleKillFlanger(1)" (value)="handleFlanger(1, $event)"></knob>
    <knob label="Cutoff" (kill)="handleKillCutoff(1)" (value)="handleCutoff(1, $event)"></knob>
    <knob label="Resonance" (kill)="handleKillResonance(1)" (value)="handleResonance(1, $event)"></knob>
  </div>
</div>

<volume
  class="volume player-1"
  [playing]="isPlaying1$ | async"
  [levell1Value]="(levels1$ | async)[0]"
  [levell2Value]="(levels1$ | async)[1]"
  (volume)="handleVolume(0, $event)"
></volume>

<!--<volume [playing]="isPlaying1$ | async"></volume>//-->
<um-crossfader></um-crossfader>

<volume
  class="volume player-2"
  [playing]="isPlaying2$ | async"
  [levell1Value]="(levels2$ | async)[0]"
  [levell2Value]="(levels2$ | async)[1]"
  (volume)="handleVolume(1, $event)"
></volume>

<!--<volume [playing]="isPlaying2$ | async"></volume>//-->

<div class="eq player-2">
  <div class="eq-knobs">
    <knob label="HIGH" (kill)="handleKillEqHigh(2)" (value)="handleEQHigh(2, $event)"></knob>
    <knob label="MID" (kill)="handleKillEqMid(2)" (value)="handleEQMid(2, $event)"></knob>
    <knob label="LOW" (kill)="handleKillEqLow(2)" (value)="handleEQLow(2, $event)"></knob>
  </div>
  <div class="eq-knobs">
    <knob label="Flanger" (kill)="handleKillFlanger(2)" (value)="handleFlanger(2, $event)"></knob>
    <knob label="Cutoff" (kill)="handleKillCutoff(2)" (value)="handleCutoff(2, $event)"></knob>
    <knob label="Resonance" (kill)="handleKillResonance(2)" (value)="handleResonance(2, $event)"></knob>
  </div>
</div>