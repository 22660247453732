<div #display class="display" dndDropzone (dndDragover)="onDragOver($event)" (dndDrop)="handleDrop($event)">

  <div class="row1">
    <div class="block track">
      <div class="label">TRACK</div>
      <div class="value" id="track" (click)="handleTrackNumberClick($event)">{{(track$ | async)?.trackNumber |
        leadingZero }}</div>
    </div>
    <div class="time-block">
      <div class="block time">
        <div class="label">+TIME</div>
        <div class="value">{{ time$ | async | time: 'mm:ss:SS'}}</div>
      </div>
      <div class="block remainingTime">
        <div class="label">-TIME</div>
        <div class="value">-{{ remainingTime$ | async | time: 'mm:ss:SS' }}</div>
      </div>
    </div>
    <div class="time-block">
      <div class="block key">
        <div class="label">KEY</div>
        <div class="value">{{(key$ | async) | percent:'1.1-1' }}</div>
      </div>
      <div class="block pitch">
        <div class="label">PITCH</div>
        <div class="value">{{(pitch$ | async) | percent:'1.1-1' }}</div>
      </div>
      <div class="block bpm" *ngIf="!(isShowingTpm$ | async)">
        <div class="label" (click)="handleOpenBpmContextMenu($event)">BPM</div>
        <div class="value" id="bpm">
          {{ (track$ | async)?.bpm | number: '1.1-1' }}
        </div>
      </div>
      <div class="block tpm" *ngIf="isShowingTpm$ | async">
        <div class="label" (click)="handleOpenBpmContextMenu($event)">TPM</div>
        <div class="value" id="tpm">
          {{ (track$ | async)?.tpm | number: '1.1-1' }}
        </div>
      </div>
    </div>
  </div>
  <div class="track-info">
    <ion-spinner *ngIf="isLoading$ | async" name="crescent"></ion-spinner>
    <ng-container *ngIf="!(isLoading$ | async)">
      <app-track-ticker [track]="track$|async" (click)="handleTrackTickerClick($event)"></app-track-ticker>
    </ng-container>
  </div>
</div>