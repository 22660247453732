import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { setLevelsAction, setVolumeAction } from './../actions/mixer.actions';

export interface MixerState {
  levels1: number[];
  levels2: number[];
  volume: number[];
}

const initialState: MixerState = {
  levels1: [0, 0],
  levels2: [0, 0],
  volume: [0, 0],
};


export const mixerReducer = createReducer(
  initialState,
  on(setLevelsAction, (state, action): MixerState => {
    if (action.playerID === 1) {
      return {
        ...state,
        levels1: [action.levelLeft, action.levelRight],
      };
    } else if (action.playerID === 2) {
      return {
        ...state,
        levels2: [action.levelLeft, action.levelRight],
      };
    }
    return state;
  }),
  on(setVolumeAction, (state, action): MixerState => {
    return {
      ...state,
      volume: setVolume(state, action.playerID, action.volume),
    };
  })
);

function setVolume(state: MixerState, playerID: number, volume: number): number[] {
  const volumes = [...state.volume];
  volumes[playerID] = volume;
  return volumes;
}

// All getter of general state
export const mixerState = createFeatureSelector<MixerState>('mixer');
export const levels1 = createSelector(mixerState, (state: MixerState) => state.levels1);
export const levels2 = createSelector(mixerState, (state: MixerState) => state.levels2);

export const volumeValues = createSelector(mixerState, (state: MixerState) => state.volume);
export const getVolume = (playerID: number) => createSelector(volumeValues, (values) => values[playerID]);
