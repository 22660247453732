<p-contextMenu #cmPlaylist [model]="menuItemsPlaylist"></p-contextMenu>

<div class="root">

  <p-table #table [columns]="cols" [value]="tracks$ | async" class="table" styleClass="p-datatable-striped"
    [resizableColumns]="true" dataKey="_id" [rowHover]="true" [reorderableColumns]="true" [scrollable]="true"
    [scrollHeight]="'flex'" [loading]="tracksLoading$ |async" selectionMode="multiple" [(selection)]="_selectedTracks"
    [metaKeySelection]="true" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
    [virtualScroll]="false" [virtualScrollItemSize]="20" (onRowReorder)="onRowReorder($event)"
    (onEditInit)="handleDisableEdit()" [tableStyle]="{'min-width': '100%'}" [contextMenu]="cmPlaylist">

    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width" />
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th [pSortableColumn]="col.header" *ngFor="let col of cols" pResizableColumn pReorderableColumn>
          {{ col.header }}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-track let-columns="columns" let-rowIndex="rowIndex">
      <tr [dndDraggable]="track" (onDragStart)="onDragStart($event,track)" [pContextMenuRow]=" track"
        [pSelectableRow]="track" [pSelectableRowIndex]="rowIndex" (dblclick)="handleLoadTrack(track)"
        [pReorderableRow]="rowIndex">

        <td *ngFor="let col of columns" class="p-resizable-column" (dblclick)="handleDblClick()" pEditableColumn>
          <ng-container [ngSwitch]="col.field">
            <div *ngSwitchCase="'no'" pReorderableRowHandle>
              {{ rowIndex + 1 }}
            </div>
            <div *ngSwitchCase="'cover'" pReorderableRowHandle>
              <img src="./assets/img/djT-check-that-body-cover.webp" class="cover" />
            </div>
            <div *ngSwitchCase="'length'" pReorderableRowHandle>
              {{ track[col.field] | time: 'mm:ss:SS' }}
            </div>
            <div *ngSwitchCase="'rating'" pReorderableRowHandle>
              <p-rating [(ngModel)]="track.rating" [cancel]="false" />
            </div>
            <div *ngSwitchCase="'actions'" pReorderableRowHandle>
              <ion-button size="small" fill="outline" (click)="loadTrackIntoPlayer(track, 1)">A</ion-button>
              <ion-button size="small" fill="outline" (click)="loadTrackIntoPlayer(track, 2)">B</ion-button>
            </div>
            <div *ngSwitchDefault pReorderableRowHandle>

              @if(editable$ |async) {
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="track[col.field]">
                </ng-template>
                <ng-template pTemplate="output">
                  {{track[col.field]}}
                </ng-template>
              </p-cellEditor>
              }
              @else {
              {{track[col.field]}}
              }

            </div>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>