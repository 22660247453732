import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import gsap from 'gsap';
import { Observable, distinctUntilChanged, map, tap } from 'rxjs';
import {
  highlightTrackInPlaylistAction,
  loadIntoPlayerAction,
  triggerSoundplayerBpmContextMenuAction,
  triggerTickerContextMenuAction,
} from '../../../core/redux/actions/player.actions';
import {
  getBpm,
  getKey,
  getPitch,
  getRemainingTime,
  getTime,
  getTrack,
  isEndOfSongAlmostReached,
  isLoading,
  isShowingTpm,
} from '../../../core/redux/reducers/player.reducer';
import { Track } from '../../../music-archive/track';
import { DndDropEvent } from 'ngx-drag-drop';

declare const window: Window &
  typeof globalThis & {
    UMWeb: {
      soundplayer: {
        startEndOfSongWarningAnimation: () => void;
        stopEndOfSongWarningAnimation: () => void;
      };
    };
  };

export interface EndOfSongWarningAnimationEventPayload {
  playerId: number;
  command: 'start' | 'stop';
}

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayComponent implements OnInit, AfterViewInit {
  // inject
  private store: Store = inject(Store);
  private destroyRef = inject(DestroyRef);
  private route = inject(ActivatedRoute);

  @Input('playerID') playerID: number;
  @ViewChild('display') display: ElementRef;

  time$: Observable<number>;
  remainingTime$: Observable<number>;
  pitch$: Observable<number>;
  key$: Observable<string>;
  track$: Observable<Track>;
  track: Track;
  isLoading$: Observable<boolean>;
  isEndOfSongAlmostReached$: Observable<boolean>;
  bpm$: Observable<number>;
  isShowingTpm$: Observable<boolean>;

  endOfSongWarningTL: gsap.core.Timeline;

  constructor() {
    this.playerID = Number(this.route.snapshot.paramMap.get('playerID'));
  }

  ngAfterViewInit(): void {
    this.createEndOfSongWarningAnimation();
    this.isEndOfSongAlmostReached$
      .pipe(
        distinctUntilChanged(),
        tap((isEndOfSongAlmostReached: boolean) => {
          if (isEndOfSongAlmostReached) {
            this.startEndOfSongWarningAnimation();
          } else {
            this.stopEndOfSongWarningAnimation();
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  ngOnInit() {
    this.pitch$ = this.store.select(getPitch(this.playerID));
    this.key$ = this.store.select(getKey(this.playerID));
    this.time$ = this.store.select(getTime(this.playerID));
    this.remainingTime$ = this.store.select(getRemainingTime(this.playerID));
    this.track$ = this.store.select(getTrack(this.playerID)).pipe(map((track: Track) => (this.track = track)));
    this.isLoading$ = this.store.select(isLoading(this.playerID));
    this.isEndOfSongAlmostReached$ = this.store.select(isEndOfSongAlmostReached(this.playerID));
    this.bpm$ = this.store.select(getBpm(this.playerID));
    this.isShowingTpm$ = this.store.select(isShowingTpm(this.playerID));
  }

  createEndOfSongWarningAnimation() {
    this.endOfSongWarningTL = gsap.timeline({
      yoyo: true,
      repeat: -1,
      paused: true,
    });
    this.endOfSongWarningTL.to(this.display.nativeElement, { duration: 0.9, backgroundColor: '#831E1E', ease: 'power1.inOut' });
  }

  startEndOfSongWarningAnimation(): void {
    this.endOfSongWarningTL.play();
  }
  stopEndOfSongWarningAnimation(): void {
    this.endOfSongWarningTL.pause().progress(0);
  }

  handleOpenBpmContextMenu(event: PointerEvent): void {
    this.store.dispatch(triggerSoundplayerBpmContextMenuAction({ playerID: this.playerID, posX: event.x, posY: event.y }));
  }

  handleTrackNumberClick(event: PointerEvent): void {
    this.store.dispatch(highlightTrackInPlaylistAction({ playerID: this.playerID }));
  }

  handleTrackTickerClick(event: PointerEvent): void {
    this.store.dispatch(triggerTickerContextMenuAction({ playerID: this.playerID, posX: event.x, posY: event.y }));
  }

  // TODO: event triggered by swing
  dummy1() {
    window.dispatchEvent(
      new CustomEvent('soundplayer::display::endOfSongWarningAnimation', {
        detail: { playerId: 1, command: 'start' } as EndOfSongWarningAnimationEventPayload,
      })
    );
  }
  // TODO: event triggered by swing
  dummy2() {
    window.dispatchEvent(
      new CustomEvent('soundplayer::display::endOfSongWarningAnimation', {
        detail: { playerId: 1, command: 'stop' } as EndOfSongWarningAnimationEventPayload,
      })
    );
  }

  handleDrop(event: DndDropEvent) {
    // alert('handleDrop: yo');
    console.log('handleDrop', event, JSON.stringify(event, null, 2));
    this.store.dispatch(loadIntoPlayerAction({ playerID: this.playerID, track: event.data as Track }));
  }

  onDragOver(event: DragEvent) {
    // console.log('dragover', JSON.stringify(event, null, 2));
  }
}
