import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSegment } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Modes } from '../../core/enums/modes.enum';
import { Views } from '../../core/enums/views.enum';
import {
  changeMode,
  changeNoPlayers,
  changeView,
  showBeatMakerAction,
  showMixerAction,
  showMusicArchiveAction,
  toggleFullScreenModeAction,
} from '../../core/redux/actions/general.actions';
import { NoPlayers } from '../../core/enums/no-players.enum';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @ViewChild('buttons', { static: false }) buttons: IonSegment;

  // UI bindings
  Views = Views;
  Modes = Modes;
  NoPlayers = NoPlayers;

  constructor(private store: Store) {}

  ngOnInit() {}

  toggleFullscreenMode() {
    this.store.dispatch(toggleFullScreenModeAction());
  }

  handleShowMusicArchive() {
    this.store.dispatch(showMusicArchiveAction());
  }

  handleShowMixer() {
    this.store.dispatch(showMixerAction());
  }

  handleShowBeatMaker() {
    this.store.dispatch(showBeatMakerAction());
  }

  handleViewChanged(event: CustomEvent) {
    this.store.dispatch(changeView({ view: event.detail.value }));
  }

  handlePlayerChanged(event: CustomEvent) {
    this.store.dispatch(changeNoPlayers({ noPlayers: event.detail.value }));
  }

  handleModeChanged(event: CustomEvent) {
    this.store.dispatch(changeMode({ mode: event.detail.value }));
  }

  segmentChanged(event: CustomEvent) {
    console.log(event);

    // we should use handleModeChanged here as an replacement

    switch (event.detail.value) {
      case 'mixer':
        this.handleShowMixer();
        break;
      case 'music':
        this.handleShowMusicArchive();
        break;
      case 'beatmaker':
        this.handleShowBeatMaker();
        break;
    }
  }
}
