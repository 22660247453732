import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Options } from 'ngx-slider-v2';

@Component({
  selector: 'app-pitch-fader',
  templateUrl: './pitch-fader.component.html',
  styleUrls: ['./pitch-fader.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PitchFaderComponent implements OnInit {
  @Output('pitch') pitch = new EventEmitter<number>();

  value: number = 0;

  
  options: Options = {
    floor: -1,
    ceil: 1,
    vertical: true,
    showTicks: true,
    tickStep: 0.1,
    step: 0.1,
    showSelectionBar: true,
    hidePointerLabels: true,
    hideLimitLabels: true,
    showSelectionBarFromValue: 0,
  };

  constructor() {}

  ngOnInit() {}

  handlePitchChanged(value: number) {
    this.pitch.emit(value);
  }

  reset() {
    this.value = 0;
  }

  setValue(value: number) {
    this.value = value;
  }

  getValue() {
    return this.value;
  }
}
