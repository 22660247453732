import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  killCutoffAction,
  killEqHighAction,
  killEqLowAction,
  killEqMidAction,
  killFlangerAction,
  killResonanceAction,
  setCutoffAction,
  setEqHighAction,
  setEqLowAction,
  setEqMidAction,
  setFlangerAction,
  setResonanceAction,
  setVolumeAction,
} from '../../core/redux/actions/mixer.actions';
import { getVolume, levels1, levels2 } from '../../core/redux/reducers/mixer.reducer';
import { isPlaying } from '../../core/redux/reducers/player.reducer';

@Component({
  selector: 'mixer',
  templateUrl: './mixer.component.html',
  styleUrls: ['./mixer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MixerComponent implements OnInit {
  levels1$: Observable<number[]> = this.store.select(levels1);
  levels2$: Observable<number[]> = this.store.select(levels2);
  volume1$: Observable<number> = this.store.select(getVolume(1));
  volume2$: Observable<number> = this.store.select(getVolume(2));

  isPlaying1$: Observable<boolean> = this.store.select(isPlaying(1));
  isPlaying2$: Observable<boolean> = this.store.select(isPlaying(2));

  constructor(private store: Store) {}

  ngOnInit() {}

  handleVolume(playerID: number, volume: number) {
    this.store.dispatch(setVolumeAction({ playerID, volume }));
  }

  handleEQHigh(playerID: number, value: number) {
    this.store.dispatch(setEqHighAction({ playerID, value }));
  }

  handleEQMid(playerID: number, value: number) {
    this.store.dispatch(setEqMidAction({ playerID, value }));
  }

  handleEQLow(playerID: number, value: number) {
    this.store.dispatch(setEqLowAction({ playerID, value }));
  }

  handleKillEqHigh(playerID: number) {
    this.store.dispatch(killEqHighAction({ playerID }));
  }

  handleKillEqMid(playerID: number) {
    this.store.dispatch(killEqMidAction({ playerID }));
  }

  handleKillEqLow(playerID: number) {
    this.store.dispatch(killEqLowAction({ playerID }));
  }

  handleFlanger(playerID: number, value: number) {
    this.store.dispatch(setFlangerAction({ playerID, value }));
  }

  handleResonance(playerID: number, value: number) {
    this.store.dispatch(setResonanceAction({ playerID, value }));
  }

  handleCutoff(playerID: number, value: number) {
    this.store.dispatch(setCutoffAction({ playerID, value }));
  }

  handleKillFlanger(playerID: number) {
    this.store.dispatch(killFlangerAction({ playerID }));
  }

  handleKillResonance(playerID: number) {
    this.store.dispatch(killResonanceAction({ playerID }));
  }

  handleKillCutoff(playerID: number) {
    this.store.dispatch(killCutoffAction({ playerID }));
  }
}
