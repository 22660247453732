import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { Track } from '../../../music-archive/track';
import { Group } from '../../../services/group';
import { ActionWithPayload } from '../actions/action.interface';
import {
  AddGroupSuccessfulAction,
  ArchiveActions,
  GroupChangedAction,
  RemoveGroupAction,
  RemoveGroupSuccessfulAction,
  RenameGroupSuccessfulAction,
  loadGroupsFailedAction as loadGroupsFailedAction,
  loadGroupsSucceededAction as loadGroupsSucceededAction,
  loadTracksAction,
  loadTracksSucceededAction,
} from '../actions/archive.actions';
import { LoadTracksFailedAction, loadGroupsAction } from './../actions/archive.actions';

export interface ArchiveState {
  groups: TreeNode[];
  loadingGroupID: string;
  tracks: Track[];
  selectedGroupID: string;
  loadingTracks: boolean;
  loadingGroups: boolean;
}

const initialState: ArchiveState = {
  groups: [],
  loadingGroupID: null,
  selectedGroupID: null,
  tracks: [],
  loadingTracks: false,
  loadingGroups: false,
};

export const archiveReducer = createReducer(
  initialState,

  on(loadTracksAction, (state, action): ArchiveState => {
    return {
      ...state,
      loadingGroupID: action.groupID,
      selectedGroupID: action.groupID,
      loadingTracks: true,
    };
  }),
  on(loadTracksSucceededAction, (state, action): ArchiveState => {
    return { ...state, tracks: action.tracks, loadingGroupID: null, loadingTracks: false };
  }),
  on(loadGroupsAction, (state, action): ArchiveState => {
    return {
      ...state,
      loadingGroups: true,
    };
  }),
  on(loadGroupsSucceededAction, (state, action): ArchiveState => {
    return { ...state, loadingGroups: false, groups: sortGroups(action.groups) };
  }),

  on(loadGroupsFailedAction, (state, action): ArchiveState => {
    return { ...state, loadingGroups: false, groups: [] };
  })
);

export function archiveReducer2(state: ArchiveState = initialState, action: ActionWithPayload): ArchiveState {
  switch (action.type) {
    case ArchiveActions.LOAD_TRACKS_FAILED: {
      const a = action as LoadTracksFailedAction;
      return { ...state, tracks: [], loadingGroupID: null };
    }
    case ArchiveActions.REMOVE_GROUP: {
      const a = action as RemoveGroupAction;
      return { ...state, loadingGroupID: a.payload.group.data._id };
    }
    case ArchiveActions.REMOVE_GROUP_SUCCESSFUL: {
      const a = action as RemoveGroupSuccessfulAction;
      const newGroups = [...state.groups];
      newGroups.splice(state.groups.indexOf(a.payload.group), 1);
      return {
        ...state,
        loadingGroupID: null,
        groups: [...newGroups],
      };
    }
    /**
     * Updated groups tree bei Änderungen, die von der remote Datenbank kommen! Live-Sync.
     */
    case ArchiveActions.GROUP_CHANGED: {
      const a = action as GroupChangedAction;

      return {
        ...state,
        loadingGroupID: null,
        groups: changeGroup(state.groups, a.payload.group),
      };
    }
    case ArchiveActions.RENAME_GROUP_SUCCESSFUL: {
      const a = action as RenameGroupSuccessfulAction;

      return {
        ...state,
        loadingGroupID: null,
        groups: changeGroup(state.groups, a.payload.group.data),
      };
    }
    case ArchiveActions.ADD_GROUP_SUCCESSFUL: {
      const a = action as AddGroupSuccessfulAction;

      let newGroups = [...state.groups];
      newGroups.push(a.payload.group);

      return {
        ...state,
        groups: sortGroups(newGroups),
      };
    }
    default: {
      return state;
    }
  }
}

function changeGroup(groups: TreeNode[], group: Group): TreeNode[] {
  let newGroups = [...groups];

  let newTreeNode = Group.treeNodeFromGroup(group);
  const oldTreeNodeIndex = getTreeNodeIndexByGroupId(groups, group._id);

  newGroups[oldTreeNodeIndex] = newTreeNode;

  return newGroups;
}

function getTreeNodeIndexByGroupId(groups: TreeNode[], groupID: string): number {
  return groups.findIndex((treeNode, index) => treeNode.key === groupID);
}

function getTreeNodeByGroupId(groups: TreeNode[], groupID: string): TreeNode {
  return groups.find((treeNode, index) => treeNode.key === groupID);
}

function sortGroups(groups: TreeNode[]): TreeNode[] {
  return groups.slice().sort((a: TreeNode, b: TreeNode) => {
    return a.label.localeCompare(b.label);
  });
}

export const archiveState = createFeatureSelector<ArchiveState>('archive');
export const groups = createSelector(archiveState, (state: ArchiveState) => state.groups);
export const loadingGroupID = createSelector(archiveState, (state: ArchiveState) => state.loadingGroupID);
export const tracks = createSelector(archiveState, (state: ArchiveState) => [...state.tracks]);
export const noTracks = createSelector(archiveState, (state: ArchiveState) => state.tracks.length);
export const isLoadingTracks = createSelector(archiveState, (state: ArchiveState) => state.loadingTracks);
export const isLoadingGroups = createSelector(archiveState, (state: ArchiveState) => state.loadingGroups);
export const selectedGroupID = createSelector(archiveState, (state: ArchiveState) => state.selectedGroupID);
export const noTracksLength = createSelector(archiveState, (state: ArchiveState) => {
  let length = 0;
  for (let track of state.tracks) {
    length += track.length;
  }
  return length;
});
