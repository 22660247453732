<div class="root">
  <div class="tree-container">
    <p-tree *ngIf="true" #tree [value]="groups$ | async" [virtualScroll]="false" [scrollHeight]="'flex'"
      [contextMenu]="cm" (onNodeExpand)="loadNode($event)" selectionMode="single" [(selection)]="selectedGroup"
      [filter]="true" [filterPlaceholder]="'Search groups'" [loading]="isLoadingGroups$ | async " class="tree"
      (onNodeSelect)="handleGroupSelect($event);">

      <ng-template let-node pTemplate="group">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <!--<img class="tree-icon" src="{{node.icon}}" />//-->
          <ion-spinner style="--color: white;margin-right: 10px;" name="dots"
            *ngIf="(loadingGroupID$ | async)===node.data._id"></ion-spinner>
          <div>
            {{node.label}}
          </div>
        </div>
      </ng-template>

      <ng-template let-node pTemplate="picture">
        <div>pic</div>
      </ng-template>

    </p-tree>
  </div>
  <div class="footer">
    <button (click)="handleAddDirectory()">+ Add Dir</button>
    <button (click)="handleNewGroup()">+ New Group</button>
  </div>
  <input type="file" id="avatar" webkitdirectory (change)="onFileChange($event)" #fileInput hidden>
</div>

<p-contextMenu #cm [model]="menuItems"></p-contextMenu>