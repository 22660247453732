import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentsModule } from '../../components/components.module';
import { KnobComponent } from '../../components/knob/knob.component';
import { CrossfaderComponent } from './crossfader/crossfader.component';
import { MixerComponent } from './mixer.component';
import { LevelComponent } from './volume/level/level.component';
import { LevelCanvasComponent } from './volume/levelcanvas/levelcanvas.component';
import { LevelsvgComponent } from './volume/levelsvg/levelsvg.component';
import { VolumeComponent } from './volume/volume.component';

@NgModule({
  imports: [CommonModule, ComponentsModule, KnobComponent],
  declarations: [MixerComponent, VolumeComponent, LevelComponent, LevelsvgComponent, LevelCanvasComponent, CrossfaderComponent],
  exports: [MixerComponent, VolumeComponent, LevelComponent, LevelsvgComponent, LevelCanvasComponent],
  providers: [],
})
export class MixerModule {}
