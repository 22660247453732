import { ElementRef, Injectable, NgZone, OnInit } from '@angular/core';
import { BoxGeometry, Camera, Color, Mesh, MeshBasicMaterial, PerspectiveCamera, Scene, WebGLRenderer } from 'three';
import { VideoTexture } from '../live-visual/VideoTexture';

@Injectable({
  providedIn: 'root',
})
export class VisualsService implements OnInit {
  width = 200;
  height = 150;

  private camera: Camera;
  private scene = new Scene();
  private renderer1: WebGLRenderer;
  private renderer2: WebGLRenderer;
  private renderer3: WebGLRenderer;
  private video: HTMLElement;
  private videoTexture: VideoTexture;
  private updateFcts = [];

  private mixPreviewHeight = 0;
  private videoPreviewHeight = 0;

  private lastTimeMsec = null;
  private animationId: number;

  isPlaying = false;

  constructor(public ngZone: NgZone) {
    console.log('constructor: visuals service');
     this.initScene();
  }

  ngOnInit(): void {
    console.log('onInit');
  }

  initScene() {
    this.scene = new Scene();
    this.scene.background = new Color(0x000000);

    // camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    // camera.position.z = 5;

    this.camera = new PerspectiveCamera(50, this.width / this.height, 1, 100);
    this.camera.position.z = 1.5;

    /*
        this.renderer1 = new WebGLRenderer({antialias: false});
        this.renderer1.setPixelRatio(window.devicePixelRatio);
        this.renderer1.setSize(this.width, this.mixPreviewHeight);


        this.renderer2 = new WebGLRenderer({antialias: false});
        this.renderer2.setPixelRatio(window.devicePixelRatio);
        this.renderer2.setSize(this.width / 2, this.height / 2);

        this.renderer3 = new WebGLRenderer({antialias: false});
        this.renderer3.setPixelRatio(window.devicePixelRatio);
        this.renderer3.setSize(this.width / 2, this.height / 2);
        */

    // create the videoTexture
    this.videoTexture = new VideoTexture('assets/video/HappyBirthday.mp4');
    console.log('>>> this.videoTexture', this.videoTexture);
    this.updateFcts.push((delta, now) => {
      // to update the texture are every frame
      // this.videoTexture.update(delta, now)
      // console.log('>>> update video texture');
      this.videoTexture.update();
    });

    // use the texture in a THREE.Mesh
    const geometry = new BoxGeometry(1, 1, 1);
    const material = new MeshBasicMaterial({
      map: this.videoTexture.texture,
    });
    const videoMesh = new Mesh(geometry, material);
    this.scene.add(videoMesh);

    console.log('this.videoTexture');
    console.log(this.videoTexture);

    // $("#window1").append(renderer1.domElement);

    /*
        this.canvasContainer.nativeElement.appendChild(
//            hostElement,
            this.renderer1.domElement
        );

        this.preview1.nativeElement.appendChild(
            this.renderer2.domElement
        );
        this.preview2.nativeElement.appendChild(
            this.renderer3.domElement
        );
        */
  }

  playVideo() {
    this.isPlaying = true;
    const promise = this.videoTexture.video.play();

    if (promise !== undefined) {
      promise
        .then((_) => {
          // Autoplay started!
          console.log('started');
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
          alert('Play security error');
        });
    }

    //////////////////////////////////////////////////////////////////////////////////
    // 		loop runner							//
    //////////////////////////////////////////////////////////////////////////////////

    this.ngZone.runOutsideAngular(() => {
      requestAnimationFrame((nowMsec) => {
        this.render(nowMsec);
      });
    });

    /*
        requestAnimationFrame((nowMsec) => {
            this.render(nowMsec);
        })*/
  }

  render(nowMsec) {
    if (!this.isPlaying) {
      return;
    }

    const me = this;
    // keep looping
    requestAnimationFrame(() => me.render(nowMsec));
    // measure time
    this.lastTimeMsec = this.lastTimeMsec || nowMsec - 1000 / 60;
    const deltaMsec = Math.min(200, nowMsec - this.lastTimeMsec);
    this.lastTimeMsec = nowMsec;
    // call each update function

    this.updateFcts.forEach(function (updateFn) {
      updateFn(deltaMsec / 1000, nowMsec / 1000);
    });

    this.renderer1.render(this.scene, this.camera);
    // this.renderer2.render(this.scene, this.camera);
    // this.renderer3.render(this.scene, this.camera);
  }

  stopVideo() {
    this.isPlaying = false;

    if (this.videoTexture && this.videoTexture.video) {
      this.videoTexture.video.currentTime = 0;
      this.videoTexture.video.pause();
    }
  }

  pauseVideo() {
    this.isPlaying = false;
    if (this.videoTexture && this.videoTexture.video) {
      this.videoTexture.video.pause();
    }
  }

  addOutput(element: ElementRef) {
    let w = element.nativeElement.clientWidth;
    let h = element.nativeElement.clientHeight;
    w = 200;
    h = 150;
    this.renderer1 = new WebGLRenderer({ antialias: false });
    this.renderer1.setPixelRatio(window.devicePixelRatio);
    this.renderer1.setSize(w, h);

    console.log('>>> renderer1', element);
    element.nativeElement.appendChild(this.renderer1.domElement);
  }
}
